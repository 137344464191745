import React, { useState, useEffect } from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = () => {
  const [activePage, setActivePage] = useState("landing");

  useEffect(() => {
    const sections = ["landing", "work", "flow", "contact"];
    const observers = sections.map((sectionId) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setActivePage(sectionId);
          }
        },
        { root: null, threshold: 0.5 }
      );
      const section = document.getElementById(sectionId);
      if (section) observer.observe(section);
      return observer;
    });

    return () => {
      observers.forEach((observer, i) => {
        const section = document.getElementById(sections[i]);
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar expand="lg" className="position-absolute top-0 w-100">
        <Container>
          <Navbar.Brand onClick={() => scrollToSection("landing")}>
            <img
              src="/assets/nunu.png"
              height="30"
              className="d-inline-block align-top"
              alt="Nunu Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ms-auto">
              <Button
                className={`std-btn ${activePage === "work" ? "active" : ""}`}
                onClick={() => scrollToSection("work")}
              >
                Works
              </Button>
              <Button
                className={`std-btn mx-lg-3 ${
                  activePage === "flow" ? "active" : ""
                }`}
                onClick={() => scrollToSection("flow")}
              >
                Workflow
              </Button>
              <Button
                className={`std-btn me-lg-3 ${
                  activePage === "contact" ? "active" : ""
                }`}
                onClick={() => scrollToSection("contact")}
              >
                Contact
              </Button>
              <Link to="/resume">
                <Button className="std-btn">Resume</Button>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div id="nav-dots" className="d-flex flex-column">
        <button
          onClick={() => scrollToSection("landing")}
          className={`nav-dot ${activePage === "landing" ? "active" : ""}`}
        />
        <button
          onClick={() => scrollToSection("work")}
          className={`nav-dot ${activePage === "work" ? "active" : ""}`}
        />
        <button
          onClick={() => scrollToSection("flow")}
          className={`nav-dot ${activePage === "flow" ? "active" : ""}`}
        />
        <button
          onClick={() => scrollToSection("contact")}
          className={`nav-dot ${activePage === "contact" ? "active" : ""}`}
        />
      </div>
    </>
  );
};

export default Header;
