import React, { useRef } from "react";
import Slider from "react-slick";

const Work = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "10px",
        },
      },
    ],
  };

  const items = [
    {
      id: 1,
      src: "/assets/projects/google.jpg",
      title: "Google Pixel 3D",
      link: "https://googlepixel3d.com/",
      features: ["Three.js", "PHP", "HTML/CSS"],
      description:
        "Developed a 3D experiential website for Google's Pixel 7 and Fold launch, educating their retail staff.",
    },
    {
      id: 2,
      src: "/assets/projects/cta.jpg",
      title: "C.T. Agency",
      link: "https://ctagency.com.au",
      features: ["Wordpress", "HTML/CSS", "Bootstrap"],
      description:
        "Developed a SEO friendly, fully modular Wordpress website for C.T. Agency - conscious about longgevity and scalability.",
    },
    {
      id: 3,
      src: "/assets/projects/dbm.jpg",
      title: "Double Bay Marina",
      link: "https://doublebaymarina.com.au",
      features: ["Wordpress", "HTML/CSS", "Bootstrap"],
      description:
        "Developed a fully modular Wordpress website for Double Bay Marina, focused on minimal maintenance and ease of running.",
    },
    {
      id: 4,
      src: "/assets/projects/eastside.jpg",
      title: "Eastside Property Co.",
      link: "https://eastsidepropertyco.com",
      features: ["React.JS", "SASS", "Bootstrap"],
      description:
        "Developed a fully modular React.JS website with a custom CMS for Eastside Property Co, ensuring ease of updating and scalability.",
    },
    {
      id: 5,
      src: "/assets/projects/shapers.jpg",
      title: "Tomorrow Shapers",
      link: "https://quiz-inventoraward.epo.org/",
      features: ["PHP", "HTML/CSS", "Three.js"],
      description:
        "Developed a 3D experiential quiz for the European Patent Office, evaluating the user's personality and summoning a custom 3D model inventor.",
    },
  ];

  return (
    <div id="work" className="vh-100 d-flex flex-column justify-content-center">
      <div className="slider-container">
        <h1>THE WORKS.</h1>
        <Slider ref={sliderRef} {...settings}>
          {items.map((item, index) => (
            <div
              key={item.id}
              onClick={() => sliderRef.current.slickGoTo(index)}
              className="d-flex justify-content-center flex-column align-items-center"
            >
              <a href={item.link} target="_blank" rel="noreferrer">
                <h2 className="fs-3">{item.title}</h2>

                <div className="position-relative">
                  <img src={item.src} alt={item.alt} />

                  {/* <p className="work-desc p-3">{item.description}</p> */}

                  <div className="work-features pb-2">
                    {item.features.map((feature, index) => (
                      <div key={index}>{feature}</div>
                    ))}
                  </div>
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Work;
