import { Col, Row } from "react-bootstrap";

const Flow = () => {
  const items = [
    {
      title: "Getting the chat going!",
      desc: "The first step in the process is always getting the chat going! This is where we begin discussing the problem, and how we can solve it with an incredible bespoke web experience.",
    },
    {
      title: "Brainstorming the idea!",
      desc: "This is where the brainstorming begins! We know what problem we need to solve - now we just need to figure out how we’re going to solve it.",
    },
    {
      title: "Writing the spec!",
      desc: "We now have the solution, we just need to evaluate exactly what it’ll take to bring it to life! With this process we’ll formally document the exact requirements.",
    },
    {
      title: "All hands on deck!",
      desc: "After a review and acceptance of the spec, all hands on deck! We start the build according to the documented timeline.",
    },
    {
      title: "The final review!",
      desc: "Before we hand over the experience to you we need to make sure it has satisfied the spec. We’ll pass it over to you for a thorough review, and if we’re happy and you’re happy then we go live!",
    },
  ];
  return (
    <div id="flow" className="container">
      <h1>THE FLOW.</h1>
      {items.map((item, index) => (
        <Row className="mb-4" key={index}>
          <Col
            key={index}
            lg={5}
            className="d-flex align-items-center flex-column mb-3 mb-lg-0"
          >
            <p className="number p-3">{index + 1}</p>
            <i className="fa fa-pipe" />
            <p className="title w-100 p-3">{item.title}</p>
          </Col>
          <Col>
            <p className="desc h-100 w-100 d-flex align-items-center justify-content-cente">
              {item.desc}
            </p>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default Flow;
