import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.scss";
import "animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "./components/Header";
import Landing from "./sections/Landing";
import Footer from "./components/Footer";
import Work from "./sections/Work";
import Flow from "./sections/Flow";
import Contact from "./sections/Contact";
import Resume from "./sections/Resume";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function Main() {
  return (
    <>
      <Header />
      <Landing />
      <Work />
      <Flow />
      <Contact />
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/resume" element={<Resume />} />
      </Routes>
    </Router>
  );
}

export default App;
