import React from "react";

const Footer = ({ top = false }) => {
  return (
    <footer
      className={`${
        !top ? "position-fixed" : ""
      } d-flex justify-content-between px-3 px-sm-4 px-lg-5 py-3`}
      style={top ? { top: "0", bottom: "0" } : {}}
    >
      <p className="fw-bold">DEVELOPER & DESIGNER</p>
      {/* <p>+61 434 433 533</p> */}
      <p>noah@nunu.pro</p>
    </footer>
  );
};

export default Footer;
