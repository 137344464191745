import { Col, Row } from "react-bootstrap";

const Landing = () => {
  return (
    <div id="landing" className="vh-100 mt-lg-0 overflow-hidden">
      <Row>
        <Col className="d-flex justify-content-center flex-column">
          <h1 className="display-1 animate__animated animate__bounce animate__repeat-2">
            HI!
          </h1>
          <p>
            I’m Noah, a software developer and designer passionate about using
            software, especially web-based software to take a project to the
            next level. I’m an all-rounder, not too nerdy, not too creative - I
            bridge my passion for programming and eye for design to ensure my
            skillset is well-rounded and applicable to a wide range of use
            cases.
          </p>
        </Col>
        <Col
          lg={6}
          className="px-0 text-center d-flex justify-content-center position-relative"
        >
          <img className="vh-100" src="/assets/headshot.png" alt="Headshot" />
          <div className="footer-blend d-lg-block d-none"></div>
        </Col>
      </Row>
    </div>
  );
};

export default Landing;
