import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import { useState } from "react";
import { Link } from "react-router-dom";

const Resume = () => {
  const [activeExp, setActiveExp] = useState("formal");
  return (
    <>
      <div id="resume" className="d-flex justify-content-center flex-column">
        <Link to="/">
          <h1 className="w-100 text-center display-1 m-0 fw-bold mb-3 mt-2">
            NOAH MELAMED
          </h1>
        </Link>
        <Footer top />
        <Row>
          <Col className="d-flex flex-column" sm={5} lg={3}>
            <img
              src="/assets/headshot.png"
              alt="Headshot"
              className="img-fluid"
            />

            <div id="resume-left-panel" className="h-100">
              <div>
                <h2>TECHNICAL OVERVIEW</h2>
                <p>
                  In my years as a software developer I’ve developed extensive
                  experience in web development with a particular proficiency in
                  front-end. Although, I’ve also had the opportunity to develop
                  sophisticated APIs for use in web applications built for
                  clients such as Google, Cisco and UNIQLO.
                </p>
              </div>
              <div id="prog-skills" className="mt-4">
                <h2>PROGRAMMING SKILLS</h2>
                <Row className="my-4">
                  <Col>Vanilla Web Dev</Col>
                  <Col>
                    <ProgressBar now={95} />
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col>React</Col>
                  <Col>
                    <ProgressBar now={80} />
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col>Node.js</Col>
                  <Col>
                    <ProgressBar now={75} />
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col>Python</Col>
                  <Col>
                    <ProgressBar now={65} />
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col>PHP</Col>
                  <Col>
                    <ProgressBar now={65} />
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col>SQL</Col>
                  <Col>
                    <ProgressBar now={65} />
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col>Java</Col>
                  <Col>
                    <ProgressBar now={55} />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col sm={7} lg={9}>
            <Container>
              <h1 className="display-3 fw-bold mt-2 mt-md-0">HI!</h1>
              <p>
                I’m Noah, a software developer and designer passionate about
                using software, especially web-based software to take a project
                to the next level. I’m an all-rounder, not too nerdy, not too
                creative - I bridge my passion for programming and eye for
                design to ensure my skillset is well-rounded and applicable to a
                wide range of use cases.
              </p>

              <div className="my-4">
                <h2>EDUCATION</h2>
                <ul>
                  <li className="my-2">
                    <h4>University of New South Wales</h4>
                    <p>Bachelor of Computer Science</p>
                    <p>2022 - 2025</p>
                  </li>
                  <li>
                    <h4>Reddam House</h4>
                    <p>Graduated High School as a High Achiever</p>
                    <p>2016 - 2021</p>
                  </li>
                </ul>
              </div>

              <h2 className="mb-4">
                <button
                  className={`std-btn px-2 fs-3 fw-bold ${
                    activeExp === "formal" ? "active" : null
                  }`}
                  onClick={() => setActiveExp("formal")}
                >
                  FORMAL
                </button>
                <button
                  className={`std-btn px-2 mx-2 fs-3 fw-bold ${
                    activeExp === "volunteer" ? "active" : null
                  }`}
                  onClick={() => setActiveExp("volunteer")}
                >
                  VOLUNTEER
                </button>
                EXPERIENCE
              </h2>

              {activeExp === "formal" ? (
                <>
                  <div className="mb-4">
                    <h5 className="fw-bold">
                      Casual Academic - University of New South Wales
                    </h5>
                    <p>
                      <strong>Employment Period:</strong> 2022 - Current
                    </p>
                    <p>
                      I have tutored for UNSW’s largest course, Intro to
                      Programming. Teaching classes of ~30 students and further
                      supporting the course of ~1000 students per term has
                      allowed me to pass my passion for programming onto a
                      diverse range of undergrad and postgrad students.
                    </p>
                  </div>
                  <div className="my-4">
                    <h5 className="fw-bold">
                      Freelance - Web Design & Development
                    </h5>
                    <p>
                      <strong>Employment Period:</strong> 2021 - Current
                    </p>
                    <p>
                      For numerous years I’ve had the opportunity to work with
                      clients of a diverse range of industries in providing
                      branding and web development. From working with
                      real-estate agencies to telecommunications companies, I’ve
                      had to cater the web format to a wide array of use cases.
                    </p>
                  </div>
                  <div className="my-4">
                    <h5 className="fw-bold">
                      Junior Technologist - 1000heads Sydney
                    </h5>
                    <p>
                      <strong>Employment Period:</strong> 2022 - 2024
                    </p>
                    <p>
                      After completing an internship with 1000heads I took on a
                      permanent part-time position bringing marketing campaigns
                      to life. Through formats such as Web, VR and Physical
                      Experientials I had the opportunity to work with clients
                      such as Google, Cisco, UNIQLO and Lenovo.
                    </p>
                  </div>
                  <p>
                    In addition, years of experience in hospitality since 2017.
                  </p>
                </>
              ) : (
                <>
                  <div className="mb-4">
                    <h5 className="fw-bold">
                      Development Director - CSESoc CompClub
                    </h5>
                    <p>
                      <strong>Employment Period:</strong> Jan 2023 - Present
                    </p>
                    <p>
                      Serving as Development Director for CompClub, I helped
                      organize and develop educational workshops and activities
                      to support students in the computing field as well as
                      coordinate a team in maintaining and re-developing
                      existing infrastructure including their website and
                      educational resources.
                    </p>
                  </div>

                  <div className="my-4">
                    <h5 className="fw-bold">
                      Online Director - UNSW Computer Science and Engineering
                      Society (CSESoc)
                    </h5>
                    <p>
                      <strong>Employment Period:</strong> Jan 2023 - Present
                    </p>
                    <p>
                      As Online Director, I overseed the digital presence of
                      CSESoc, ensuring engaging online content and smooth
                      communication with society members.
                    </p>
                  </div>

                  <div className="my-4">
                    <h5 className="fw-bold">
                      Marketing Director - UNSW Security Society (SecSoc)
                    </h5>
                    <p>
                      <strong>Employment Period:</strong> Jan 2023 - Present
                    </p>
                    <p>
                      In my role as Marketing Director, I lead the marketing
                      efforts for SecSoc, coordinating campaigns to promote
                      events and increase awareness of cybersecurity education.
                    </p>
                  </div>

                  <div className="my-4">
                    <h5 className="fw-bold">
                      Marketing Subcommittee - UNSW Computer Science and
                      Engineering Society (CSESoc)
                    </h5>
                    <p>
                      <strong>Employment Period:</strong> Jan 2022 - Dec 2022
                    </p>
                    <p>
                      As a member of the CSESoc Marketing Subcommittee, I
                      assisted in promoting events, managing social media, and
                      supporting the marketing team’s objectives to enhance
                      engagement.
                    </p>
                  </div>
                </>
              )}

              <div className="references mt-4 mb-4 mb-sm-3">
                <h2>REFERENCES</h2>
                <Row>
                  <Col className="mb-3 mb-lg-0">
                    <h5 className="fw-bold">Dan Koublachvili</h5>
                    <p>1000heads - Creative Tech Director</p>
                    <p>
                      <i className="fas fa-phone-alt"></i> +61 420 676 704
                    </p>
                    <p>
                      <i className="fas fa-envelope"></i>{" "}
                      daniel.koublachvili@1000heads.com
                    </p>
                  </Col>

                  <Col>
                    <h5 className="fw-bold">Sasha Vassar</h5>
                    <p>UNSW - Senior Professor</p>
                    <p>
                      <i className="fas fa-phone-alt"></i> +61 2 9348 1708
                    </p>
                    <p>
                      <i className="fas fa-envelope"></i> a.vassar@unsw.edu.au
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Resume;
