import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import emailjs from "emailjs-com";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const Contact = () => {
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          setStatus("Message sent successfully!");
          setFormData({ from_name: "", from_email: "", message: "" });
        },
        (error) => {
          setStatus("Failed to send the message. Please try again.");
        }
      );
  };

  return (
    <section
      id="contact"
      className="vh-100 d-flex align-items-center mb-lg-4 mb-xl-5"
    >
      <Container>
        <Row>
          <Col lg={7}>
            <h1 className="display-3 fw-bold mb-5">
              LET'S BRING YOUR
              <br />
              <span className="p-2">VISION TO LIFE</span>
            </h1>

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="name">
                <Form.Control
                  type="text"
                  name="from_name" // Updated to match the template
                  placeholder="Name"
                  value={formData.from_name}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="email" className="my-3">
                <Form.Control
                  type="email"
                  name="from_email" // Updated to match the template
                  placeholder="Email"
                  value={formData.from_email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="message" className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Form.Group>

              <div className="w-100 d-flex justify-content-end">
                <button
                  type="submit"
                  className="std-btn dark align-end px-3 py-1"
                >
                  Submit
                </button>
              </div>
            </Form>
            {status && <p>{status}</p>}
          </Col>
          <Col>
            <Map />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

const Map = () => (
  <MapContainer
    center={[-33.8688, 151.2093]}
    zoom={12}
    style={{ height: "100%", width: "100%" }}
    interactive={false}
    scrollWheelZoom={false}
    doubleClickZoom={false}
    zoomControl={false}
    dragging={false}
  >
    <TileLayer url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png" />
    <Marker position={[-33.8688, 151.2093]}>
      <Popup>Sydney</Popup>
    </Marker>
  </MapContainer>
);
